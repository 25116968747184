<template>
  <div
      class="tags-input"
      :class="{active: state.isActive}"
  >
    <span
        v-for="(tag, i) in modelValue"
        :key="i"
        class="tag"
    >
      {{ tag }}
      <button
          class="btn-delete"
          tabindex="-1"
          @click="removeTag(i)"
      >
        <i class="far fa-times"/>
      </button>
    </span>

    <input
        v-model="state.creatingTag"
        type="text"
        autocomplete="off"
        :placeholder="placeholder"
        @compositionstart="state.isComposing=true"
        @compositionend="state.isComposing=false"
        @keydown.delete="deleteLastTag"
        @keydown.enter.prevent="addTag"
        @focus="state.isActive = true"
        @blur="addTag(); state.isActive = false;"
    />
  </div>
</template>

<script lang="ts" src="./TagsInput.ts"></script>
<style lang="scss" src="./TagsInput.scss" scoped></style>
