import { __assign, __awaiter, __generator } from "tslib";
import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, onMounted, reactive } from "vue";
import { useForm } from "vee-validate";
import Input from "@/presentation/components/input/Input.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import SingleFileUploader from "@/presentation/components/single-file-uploader/SingleFileUploader.vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import DeliverableInteractor from "@/domain/usecases/DeliverableInteractor";
import { useStore } from "vuex";
export default defineComponent({
    name: "AchievementEditDifferentDisplayPopup",
    components: {
        Input: Input,
        Textarea: Textarea,
        DatePicker: DatePicker,
        SingleFileUploader: SingleFileUploader,
        ErrorMessage: ErrorMessage,
        Preloaders: Preloaders
    },
    props: {
        type: String,
        editAchievement: {
            type: Object,
            required: false,
        },
        latestAchievement: {
            type: Object,
            required: false,
        }
    },
    setup: function (_a, _b) {
        var _this = this;
        var _c;
        var editAchievement = _a.editAchievement, latestAchievement = _a.latestAchievement;
        var emit = _b.emit;
        var now = new Date();
        var submitDate = editAchievement
            ? (_c = editAchievement === null || editAchievement === void 0 ? void 0 : editAchievement.updated_at) === null || _c === void 0 ? void 0 : _c.split(" ")[0].replace(/-/g, '/') : now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate();
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            editAchievement: editAchievement,
            latestAchievement: latestAchievement,
            source: {},
            uploadFile: null,
            uploadReportFile: null,
            deleteFileId: null,
            deleteReportFileId: null,
            preload: true,
            isSubmitting: false,
        });
        // マイルストーン登録
        var deliverableInteractor = DIContainer.instance.get(DeliverableInteractor);
        // - - -
        // validations
        var _d = useForm({
            initialValues: {
                description: null,
                note: null,
            }
        }), values = _d.values, errors = _d.errors, setValues = _d.setValues, handleSubmit = _d.handleSubmit;
        // - - -
        // actions
        onMounted(function () {
            if (editAchievement) {
                setValues(__assign(__assign({}, values), editAchievement));
            }
            state.preload = false;
        });
        var onSubmit = handleSubmit(function () { return __awaiter(_this, void 0, void 0, function () {
            var uploaded_files, uploaded_report_files, teamInteractor, file, reportReportFile, tmpDeliverable, zipMimeType, extension, preSignedUrlResponse, e_1, extension, preSignedUrlResponse, e_2, newMilestone, deliverableSource, deliverable, deliverableFiles, deliverableReportFiles, latestAchievementFiles, latestAchievementReportFiles, deliverableCondition, deliverableFileCondition, deliverableReportFileCondition, modalElement, upsertResponse, userChoice, userChoice_1, e_3, e_4, e_5;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        state.isSubmitting = true;
                        uploaded_files = [];
                        uploaded_report_files = [];
                        teamInteractor = DIContainer.instance.get(TeamInteractor);
                        file = state.uploadFile;
                        reportReportFile = state.uploadReportFile;
                        tmpDeliverable = __assign({}, values);
                        zipMimeType = ['application/zip', 'application/x-zip-compressed'];
                        if (!reportReportFile) return [3 /*break*/, 5];
                        extension = void 0;
                        if (zipMimeType.includes(reportReportFile.type)) {
                            extension = "zip";
                        }
                        else {
                            alert('zipファイルをご指定ください。');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, teamInteractor.createPreSignedUrl(extension)];
                    case 1:
                        preSignedUrlResponse = _f.sent();
                        _f.label = 2;
                    case 2:
                        _f.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, teamInteractor.uploadToPreSignedUrl(preSignedUrlResponse.data, reportReportFile)];
                    case 3:
                        _f.sent();
                        uploaded_report_files.push({
                            name: reportReportFile.name,
                            url: preSignedUrlResponse.data.url
                        });
                        tmpDeliverable.uploaded_report_files = uploaded_report_files;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _f.sent();
                        console.error(e_1);
                        alert("ファイルのアップロードに失敗しました。");
                        return [3 /*break*/, 5];
                    case 5:
                        if (!file) return [3 /*break*/, 10];
                        extension = void 0;
                        if (zipMimeType.includes(file.type)) {
                            extension = "zip";
                        }
                        else {
                            alert('zipファイルをご指定ください。');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, teamInteractor.createPreSignedUrl(extension)];
                    case 6:
                        preSignedUrlResponse = _f.sent();
                        _f.label = 7;
                    case 7:
                        _f.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, teamInteractor.uploadToPreSignedUrl(preSignedUrlResponse.data, file)];
                    case 8:
                        _f.sent();
                        uploaded_files.push({
                            name: file.name,
                            url: preSignedUrlResponse.data.url
                        });
                        tmpDeliverable.uploaded_files = uploaded_files;
                        return [3 /*break*/, 10];
                    case 9:
                        e_2 = _f.sent();
                        console.error(e_2);
                        alert("ファイルのアップロードに失敗しました。");
                        return [3 /*break*/, 10];
                    case 10: return [4 /*yield*/, deliverableInteractor.getDeliverable(store.state.projectInfo.project_id, store.state.teamInfo.team_id)];
                    case 11:
                        deliverableSource = _f.sent();
                        deliverable = deliverableSource.data;
                        deliverableFiles = (deliverable === null || deliverable === void 0 ? void 0 : deliverable.files) || [];
                        deliverableReportFiles = (deliverable === null || deliverable === void 0 ? void 0 : deliverable.report_files) || [];
                        latestAchievementFiles = ((_a = state.latestAchievement) === null || _a === void 0 ? void 0 : _a.files) || [];
                        latestAchievementReportFiles = ((_b = state.latestAchievement) === null || _b === void 0 ? void 0 : _b.report_files) || [];
                        deliverableCondition = ((_c = state.latestAchievement) === null || _c === void 0 ? void 0 : _c.updated_at) !== (deliverable === null || deliverable === void 0 ? void 0 : deliverable.updated_at);
                        deliverableFileCondition = latestAchievementFiles[latestAchievementFiles.length - 1].updated_at !== deliverableFiles[deliverableFiles.length - 1].updated_at;
                        deliverableReportFileCondition = latestAchievementReportFiles[latestAchievementReportFiles.length - 1].updated_at !== deliverableReportFiles[deliverableReportFiles.length - 1].updated_at;
                        if (deliverableCondition || deliverableFileCondition || deliverableReportFileCondition) {
                            alert("新たに更新されたデータがあります。確認してください");
                            state.isSubmitting = false;
                            emit('close');
                            state.editAchievement = tmpDeliverable;
                            emit('updateEditAchievement', tmpDeliverable);
                            state.latestAchievement = deliverable;
                            emit('updateLatestAchievement', deliverable);
                            modalElement = document.getElementById('my-modal');
                            if (modalElement) {
                                modalElement.scrollIntoView({ behavior: 'smooth' });
                            }
                            return [2 /*return*/];
                        }
                        _f.label = 12;
                    case 12:
                        _f.trys.push([12, 19, , 20]);
                        upsertResponse = void 0;
                        userChoice = window.confirm("編集中のデータが他のユーザーによって変更されました。更新しますか？");
                        if (!userChoice) {
                            alert("更新をキャンセルしました。");
                            state.isSubmitting = false;
                            emit('close');
                            return [2 /*return*/];
                        }
                        if (!editAchievement) return [3 /*break*/, 14];
                        return [4 /*yield*/, deliverableInteractor.updateDeliverable(tmpDeliverable)];
                    case 13:
                        upsertResponse = _f.sent();
                        return [3 /*break*/, 18];
                    case 14:
                        if (!(deliverable === null || deliverable === void 0 ? void 0 : deliverable.updated_at)) return [3 /*break*/, 16];
                        tmpDeliverable.project_id = deliverable.project_id;
                        tmpDeliverable.team_id = deliverable.team_id;
                        tmpDeliverable.id = deliverable.id;
                        userChoice_1 = window.confirm("編集中のデータが他のユーザーによって変更されました。更新しますか？");
                        if (!userChoice_1) {
                            alert("更新をキャンセルしました。");
                            state.isSubmitting = false;
                            emit('close');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, deliverableInteractor.updateDeliverable(tmpDeliverable)];
                    case 15:
                        upsertResponse = _f.sent();
                        return [3 /*break*/, 18];
                    case 16: return [4 /*yield*/, deliverableInteractor.createDeliverable(tmpDeliverable)];
                    case 17:
                        upsertResponse = _f.sent();
                        _f.label = 18;
                    case 18:
                        newMilestone = upsertResponse.data;
                        return [3 /*break*/, 20];
                    case 19:
                        e_3 = _f.sent();
                        console.error(e_3);
                        alert("成果物の登録に失敗しました。");
                        state.isSubmitting = false;
                        return [2 /*return*/];
                    case 20:
                        if (!(state.deleteFileId !== null)) return [3 /*break*/, 24];
                        _f.label = 21;
                    case 21:
                        _f.trys.push([21, 23, , 24]);
                        return [4 /*yield*/, deliverableInteractor.deleteDeliverableFile(state.deleteFileId)];
                    case 22:
                        _f.sent();
                        newMilestone.files = (_d = newMilestone.files) === null || _d === void 0 ? void 0 : _d.filter(function (file) { return file.id !== state.deleteFileId; });
                        return [3 /*break*/, 24];
                    case 23:
                        e_4 = _f.sent();
                        console.error(e_4);
                        state.isSubmitting = false;
                        alert("ファイルの削除に失敗しました");
                        return [2 /*return*/];
                    case 24:
                        if (!(state.deleteReportFileId !== null)) return [3 /*break*/, 28];
                        _f.label = 25;
                    case 25:
                        _f.trys.push([25, 27, , 28]);
                        return [4 /*yield*/, deliverableInteractor.deleteDeliverableFile(state.deleteReportFileId)];
                    case 26:
                        _f.sent();
                        newMilestone.files = (_e = newMilestone.report_files) === null || _e === void 0 ? void 0 : _e.filter(function (file) { return file.id !== state.deleteReportFileId; });
                        return [3 /*break*/, 28];
                    case 27:
                        e_5 = _f.sent();
                        console.error(e_5);
                        state.isSubmitting = false;
                        alert("ファイルの削除に失敗しました");
                        return [2 /*return*/];
                    case 28:
                        emit('complete', newMilestone);
                        emit('close');
                        state.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        }); });
        return {
            submitDate: submitDate,
            values: values,
            errors: errors,
            state: state,
            onSubmit: onSubmit,
        };
    }
});
