import { defineComponent, reactive } from "vue";
export default defineComponent({
    name: "TagsInput",
    props: {
        modelValue: {
            type: Array,
            default: [],
        },
        trimTagItem: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        }
    },
    setup: function (props) {
        var state = reactive({
            creatingTag: "",
            inputPadding: 0,
            isActive: false,
            isComposing: false,
        });
        var addTag = function () {
            if (!state.creatingTag || state.isComposing) {
                return;
            }
            var newTag = props.trimTagItem ? state.creatingTag.trim() : state.creatingTag;
            if (!newTag) {
                state.creatingTag = "";
                return;
            }
            props.modelValue.push(newTag);
            state.creatingTag = "";
        };
        var removeTag = function (index) {
            if (props.modelValue.length > index) {
                props.modelValue.splice(index, 1);
            }
        };
        var deleteLastTag = function () {
            if (!state.creatingTag.length && !state.isComposing) {
                removeTag(props.modelValue.length - 1);
            }
        };
        return {
            state: state,
            addTag: addTag,
            removeTag: removeTag,
            deleteLastTag: deleteLastTag,
        };
    }
});
