<template>
    <div class="file-uploader">
        <input
            type="file"
            id="upload-file"
            @error="handleUploadError"
            @change="handleUpload"
            ref="fileInputRef"
            :accept="state.accept"
        />
        <label
            :class="{
                submitting: isSubmitting
            }"
            for="upload-file"
            class="btn btn-black btn-s sweep-to-top is-upload"
        >
            ファイルアップロード
        </label>
        <span class="uploaded-files">
            <span
                v-for="(file,i) in files"
                :key="i"
            >
                {{ file.name }}
                (<span
                    class="uploaded-files__delete"
                    @click="handleDelete(i)"
                >削除</span>)
                <span v-if="i !== files.length - 1">,&nbsp;</span>
            </span>
        </span>
    </div>
</template>

<script lang="ts" src="./FileUploader.ts" />
<style lang="scss" src="./FileUploader.scss" scoped></style>
