<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div id="my-modal" class="modal-body">
                        <slot name="body">
                            <div class="modal-body">
                                <div class="modal-body__content">
                                    <div class="modal-body__content-title">
                                        <span>
                                            成果提出<br/>
                                            <p class="meeting-edit__alert">
                                                ※他のメンバーが編集した内容があります。編集内容を確認してください。
                                            </p>
                                        </span>
                                        <i class="fa fa-times" @click="$emit('close')" />
                                    </div>
                                    <div v-if="state.preload" class="modal-body__content-body meeting-edit">
                                        <Preloaders :count="10" />
                                    </div>
                                    <div v-else class="modal-body__content-body meeting-edit">
                                        <div class="meeting-edit__form">
                                            <!-- 提出日 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    提出日
                                                </label>
                                                <div class="form-content">
                                                    {{ submitDate }}
                                                </div>
                                            </div>
                                          <!-- 成果報告書(pptx/ppt/zip) -->
                                          <div class="form-row">
                                            <div class="form-ctrl">
                                              <label class="form-title">
                                                成果報告書(zip)<br />
                                                <span class="alert">
                                                            ※30MBまで　(必須)
                                                        </span>
                                              </label>
                                              <div class="form-content">
                                                <span v-if="state.latestAchievement?.report_files?.length">
                                                    <span>
                                                        <a :href="state.latestAchievement.report_files[state.latestAchievement.report_files.length - 1].url" target="_blank" rel="noreferrer noopener">
                                                            {{ state.latestAchievement.report_files[state.latestAchievement.report_files.length - 1].name }}
                                                        </a>
                                                    </span>
                                                </span>
                                                <span v-else class="empty">
                                                    未入力
                                                </span>
                                              </div>
                                            </div>
                                            <div class="form-arrow">
                                              ▶︎
                                            </div>
                                            <div class="form-ctrl">
                                              <label class="form-title"></label><br>
                                              <div class="form-content form-achievement">
                                                <SingleFileUploader
                                                    v-model="state.uploadReportFile"
                                                    :accept="'zip'"
                                                    :targetId="'upload-file-report'"
                                                />
                                                <span class="uploaded-files" v-if="state.editAchievement && state.editAchievement.uploaded_report_files && state.editAchievement.uploaded_report_files.length > 0">
                                                            <span v-if="state.editAchievement.uploaded_report_files">
                                                                <a
                                                                    class="uploaded-files__interactive"
                                                                    :href="state.editAchievement.uploaded_report_files[0].url"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {{ state.editAchievement.uploaded_report_files[0].name }}
                                                                </a>
                                                            </span>
                                                        </span>
                                                <p class="alert">
                                                  ※ファイル名には半角英数字,ハイフン,アンダースコアをご利用ください。<br>
                                                  ※1ファイルのみアップロード可能です。更新すると前のファイルは上書きされます。
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                            <!-- 成果物(zip) -->
                                            <div class="form-row">
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        成果物(zip)<br />
                                                        <span class="alert">
                                                            ※30MBまで　(必須)
                                                        </span>
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="state.latestAchievement?.files?.length">
                                                            <span>
                                                                <a :href="state.latestAchievement.files[state.latestAchievement.files.length - 1].url" target="_blank" rel="noreferrer noopener">
                                                                    {{ state.latestAchievement.files[state.latestAchievement.files.length - 1].name }}
                                                                </a>
                                                            </span>
                                                        </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-arrow">
                                                    ▶︎
                                                </div>
                                                <div class="form-ctrl">
                                                    <label class="form-title"></label><br>
                                                    <div class="form-content form-achievement">
                                                        <SingleFileUploader
                                                            v-model="state.uploadFile"
                                                            :accept="'zip'"
                                                        />
                                                        <span class="uploaded-files" v-if="state.editAchievement && state.editAchievement.uploaded_files && state.editAchievement.uploaded_files.length > 0">
                                                            <span v-if="state.editAchievement.uploaded_files">
                                                                <a
                                                                    class="uploaded-files__interactive"
                                                                    :href="state.editAchievement.uploaded_files[0].url"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {{ state.editAchievement.uploaded_files[0].name }}
                                                                </a>
                                                            </span>
                                                        </span>
                                                        <p class="alert">
                                                            ※ファイル名には半角英数字,ハイフン,アンダースコアをご利用ください。<br>
                                                            ※1ファイルのみアップロード可能です。更新すると前のファイルは上書きされます。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 成果内容 -->
                                            <div class="form-row">
                                                <div class="form-ctrl">
                                                  <label class="form-title">
                                                      成果内容
                                                  </label><br>
                                                  <div class="form-content">
                                                      {{ state.latestAchievement?.description }}
                                                  </div>
                                                </div>
                                                <div class="form-arrow">
                                                    ▶︎
                                                </div>
                                                <div class="form-ctrl">
                                                    <label class="form-title"></label><br>
                                                    <div class="form-content form-achievement">
                                                        <Textarea
                                                            placeholder="2000文字以内で入力してください"
                                                            :maxlength="2000"
                                                            v-model="values.description"
                                                        />
                                                        <ErrorMessage
                                                            v-if="errors.description"
                                                            :message="errors.description"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- 備考 -->
                                            <div class="form-row">
                                                <div class="form-ctrl">
                                                  <label class="form-title">
                                                      備考
                                                  </label><br>
                                                  <div class="form-content">
                                                      {{ state.latestAchievement?.note }}
                                                  </div>
                                                </div>
                                                <div class="form-arrow">
                                                    ▶︎
                                                </div>
                                                <div class="form-ctrl">
                                                    <label class="form-title"></label><br>
                                                    <div class="form-content form-achievement">
                                                        <Textarea
                                                            placeholder="500文字以内で入力してください"
                                                            :maxlength="500"
                                                            v-model="values.note"
                                                        />
                                                        <ErrorMessage
                                                            v-if="errors.note"
                                                            :message="errors.note"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal-body__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            @click="onSubmit"
                                            type="button"
                                            class="btn-blue btn-md shadow"
                                            :disabled="state.isSubmitting"
                                        >
                                            <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                                            保存する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./AchievementEditDifferenceDisplay.ts" />
<style lang="scss" src="./AchievementEditDifferenceDisplay.scss" scoped></style>

