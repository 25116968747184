<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="modal-body">
                                <div class="modal-body__content">
                                    <div class="modal-body__content-title">
                                        <span>
                                            {{ isKickoff ? 'キックオフ設定' : '会議設定' }}
                                        </span>
                                        <i class="fa fa-times" @click="$emit('close')" />
                                    </div>
                                    <div v-if="state.preload" class="modal-body__content-body meeting-edit">
                                        <Preloaders :count="10" />
                                    </div>
                                    <div v-else class="modal-body__content-body meeting-edit">
                                        <div class="meeting-edit__base">
                                            <p v-if="isKickoff">
                                                チームリーダーはスケジュール案を協議して作成し、日程とアジェンダを設定して企業担当者に連絡してください。会議が終わり次第、議事録などを更新してください。
                                            </p>
                                            <div class="meeting-edit__form">
                                                <!-- 会議種別 -->
                                                <div class="form-ctrl" v-if="!isKickoff">
                                                    <label class="form-title">
                                                        会議種別
                                                    </label>
                                                    <div class="form-content">
                                                        <select
                                                            v-model="values.meeting_type"
                                                            class="meeting-type-select"
                                                        >
                                                            <option value="" selected>選択</option>
                                                            <option value="REGULAR">会議</option>
                                                            <option value="REVIEW" v-if="!disableSelectReview">最終報告</option>
                                                        </select>
                                                        <ErrorMessage
                                                            v-if="errors.meeting_type"
                                                            :message="errors.meeting_type"
                                                        />
                                                    </div>
                                                </div>
                                                <!-- 実施日時 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        実施日時
                                                        <span class="alert">(必須)</span>
                                                    </label>
                                                    <div class="form-content">
                                                        <DatePicker
                                                            v-model="state.meetingDate.date"
                                                        />
                                                        <div class="time-select">
                                                            <select
                                                                v-model="state.meetingDate.startHour"
                                                            >
                                                                <option value="" selected></option>
                                                                <option
                                                                    v-for="(hour, i) in hourSource"
                                                                    :key="i"
                                                                    :value="hour"
                                                                >
                                                                    {{ hour }}
                                                                </option>
                                                            </select>
                                                            ：
                                                            <select
                                                                v-model="state.meetingDate.startMinute"
                                                            >
                                                                <option
                                                                    v-for="(minute, i) in minuteSource"
                                                                    :key="i"
                                                                    :value="minute"
                                                                    :selected="i === 0"
                                                                >
                                                                    {{ minute }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <span class="time-range">
                                                            〜
                                                        </span>
                                                        <div class="time-select">
                                                            <select
                                                                v-model="state.meetingDate.endHour"
                                                            >
                                                                <option value="" selected>選択</option>
                                                                <option
                                                                    v-for="(hour, i) in hourSource"
                                                                    :key="i"
                                                                    :value="hour"
                                                                >
                                                                    {{ hour }}
                                                                </option>
                                                            </select>
                                                            ：
                                                            <select
                                                                v-model="state.meetingDate.endMinute"
                                                            >
                                                                <option
                                                                    v-for="(minute, i) in minuteSource"
                                                                    :key="i"
                                                                    :value="minute"
                                                                    :selected="i === 0"
                                                                >
                                                                    {{ minute }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <ErrorMessage
                                                            v-if="errors.meeting_start_at || errors.meeting_end_at"
                                                            :message="errors.meeting_start_at || errors.meeting_end_at"
                                                        />
                                                    </div>
                                                </div>
                                                <!-- アジェンダ -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        アジェンダ
                                                    </label>
                                                    <div class="form-content">
                                                        <Textarea
                                                            placeholder="500文字以内で入力してください"
                                                            :maxlength="500"
                                                            v-model="values.agenda"
                                                        />
                                                        <ErrorMessage
                                                            v-if="errors.agenda"
                                                            :message="errors.agenda"
                                                        />
                                                    </div>
                                                </div>
                                                <!-- MTG資料(pdf/zip) -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        MTG資料(pdf/zip)<br />
                                                        <span class="alert">
                                                            ※30MBまで
                                                        </span>
                                                    </label>
                                                    <div class="form-content">
                                                        <FileUploader
                                                            v-model="state.uploadFiles"
                                                        />
                                                        <span
                                                            class="uploaded-files"
                                                            v-if="milestone"
                                                            v-for="(file, i) in milestone.files"
                                                            :key="file.id"
                                                        >
                                                            <span v-if="!state.deleteFileIds.includes(file.id)">
                                                                <a
                                                                    class="uploaded-files__interactive"
                                                                    :href="file.url"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {{ file.name }}
                                                                </a>
                                                                (<span
                                                                    class="uploaded-files__interactive"
                                                                    @click="state.deleteFileIds.push(file.id)"
                                                                >
                                                                    削除
                                                                </span>)
                                                                <span v-if="i !== milestone.files.length - 1">,&nbsp;</span>
                                                            </span>
                                                        </span>
                                                        <p class="alert">
                                                            ※ファイル名には半角英数字,ハイフン,アンダースコアをご利用ください。
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="meeting-edit__accodion">
                                            <div
                                                v-if="staticExpanded"
                                                class="meeting-edit__subject"
                                            >
                                                MTG終了後
                                            </div>
                                            <div class="meeting-edit__form"
                                                :class="{ collapsed: staticExpanded ? false : state.collapsed }"
                                            >
                                                <!-- 終了予定日 -->
                                                <div class="form-ctrl" v-if="isKickoff">
                                                    <label class="form-title">
                                                        終了予定日
                                                        <span class="alert">(必須)</span>
                                                    </label>
                                                    <div class="form-content">
                                                        <DatePicker
                                                            v-model="values.expected_completion_at"
                                                        />
                                                        <p class="alert">
                                                            ※プロジェクトはキックオフから2ヶ月程度で終了するよう設定してください
                                                        </p>
                                                        <ErrorMessage
                                                          v-if="errors.expected_completion_at"
                                                          :message="errors.expected_completion_at"
                                                        />
                                                      </div>
                                                </div>
                                                <!-- 企業参加者 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        企業参加者
                                                        <span class="alert">(必須)</span>
                                                    </label>
                                                    <div class="form-content">
                                                        <div class="tags-input-and-count">
                                                            <div class="tags-input-wrapper">
                                                                <TagsInput
                                                                    v-model="values.organization_members"
                                                                    placeholder="参加者を入力してください"
                                                                />
                                                            </div>
                                                            <span>
                                                                {{ values.organization_members?.length || 0 }}名
                                                            </span>
                                                        </div>
                                                        <ErrorMessage
                                                            v-if="errors.organization_members"
                                                            :message="errors.organization_members"
                                                        />
                                                    </div>
                                                </div>
                                                <!-- 受講生参加者 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        受講生参加者
                                                        <span class="alert">(必須)</span>
                                                    </label>
                                                    <div class="form-content">
                                                        <div class="tags-input-and-count">
                                                            <div class="tags-input-wrapper">
                                                                <TagsInput
                                                                    v-model="values.team_members"
                                                                    placeholder="参加者を入力してください"
                                                                />
                                                            </div>
                                                            <span>
                                                                {{ values.team_members?.length || 0 }}名
                                                            </span>
                                                        </div>
                                                        <ErrorMessage
                                                            v-if="errors.team_members"
                                                            :message="errors.team_members"
                                                        />
                                                    </div>
                                                </div>
                                                <!-- 議事録 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        議事録
                                                        <span class="alert">(必須)</span>
                                                    </label>
                                                    <div class="form-content milestone-note">
                                                        <Textarea
                                                            placeholder="10000文字以内で入力してください"
                                                            :maxlength="10000"
                                                            v-model="values.note"
                                                        />
                                                        <ErrorMessage
                                                            v-if="errors.note"
                                                            :message="errors.note"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                       介入希望
                                                    </label>
                                                    <div class="form-content">
                                                        <div class="checkbox">
                                                            <input
                                                                id="need_help"
                                                                type="checkbox"
                                                                v-model="values.need_help"
                                                            />
                                                            <label for="need_help">
                                                                事務局の介入を希望する
                                                            </label>
                                                        </div>
                                                        <Textarea
                                                            placeholder="500文字以内で理由やお困りごとを記載してください。&#13;&#10;内容を確認し、事務局からご連絡します。"
                                                            :maxlength="500"
                                                            v-model="values.help_note"
                                                        />
                                                        <p class="alert">
                                                            ※ここに記入されたメッセージは企業には公開されず、チーム内・事務局・地域ハブ団体に共有されます。
                                                        </p>
                                                        <ErrorMessage
                                                            v-if="errors.help_note"
                                                            :message="errors.help_note"
                                                        />
                                                    </div>
                                                </div>
                                                <!--キックオフ後アンケート-->
                                                <div v-if = "isKickoff" class="survey-wrapper">
                                                    <div class="survey-wrapper__main_title">
                                                        <span>キックオフ後アンケート</span>
                                                    </div>
                                                    <p class="survey-wrapper__sub_title">
                                                        キックオフを終えての感想をお聞かせてください
                                                        <br>※本欄は、入力者個人としての感想で構いません。
                                                        <br>※この内容は事務局のみに表示され相手方企業と担当地域ハブ団体には表示されません
                                                    </p>
                                                    <div class="survey-body">
                                                        <label class="survey-body__title">
                                                            Q1 キックオフを終えての所感・感想があれば教えてください
                                                        </label>
                                                        <div>
                                                            <div class="form-ctrl non-border">
                                                                <label class="form-title">
                                                                    選択
                                                                    <span class="alert">(必須)</span>
                                                                </label>
                                                                <div class="form-content">
                                                                    <select
                                                                        v-model="values.first_question_select">
                                                                        <option selected disabled>選択してください</option>
                                                                        <option v-for="row of firstQuestionnaireAnwer" :value="row">
                                                                            {{row}}
                                                                        </option>
                                                                    </select>
                                                                    <ErrorMessage
                                                                        v-if="errors.first_question_select"
                                                                        :message="errors.first_question_select"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="form-ctrl">
                                                                <label class="form-title">
                                                                    自由記述
                                                                </label>
                                                                <div class="form-content">
                                                                    <Textarea
                                                                        v-model="values.first_question_comment"
                                                                        placeholder="何かコメントがありましたらご記載ください"
                                                                        :maxlength="1000"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="survey-body__title">
                                                            Q2 2か月間で取り組むべき課題は、企業から明確に提示されましたか？
                                                        </label>
                                                        <div>
                                                            <div class="form-ctrl non-border">
                                                                <label class="form-title">
                                                                    選択
                                                                    <span class="alert">(必須)</span>
                                                                </label>
                                                                <div class="form-content">
                                                                    <select
                                                                        v-model="values.second_question_select">
                                                                        <option selected disabled>選択してください</option>
                                                                        <option v-for="row of secondQuestionnaireAnwer" :value="row">
                                                                            {{row}}
                                                                        </option>
                                                                    </select>
                                                                    <ErrorMessage
                                                                        v-if="errors.second_question_select"
                                                                        :message="errors.second_question_select"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="form-ctrl">
                                                                <label class="form-title">
                                                                    自由記述
                                                                </label>
                                                                <div class="form-content">
                                                                    <Textarea
                                                                        v-model="values.second_question_comment"
                                                                        placeholder="何かコメントがありましたらご記載ください"
                                                                        :maxlength="1000"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="survey-body__title">
                                                            Q3 次週に向けてのアクション（来週までにどうすべきか、何をすべきか）は、明確になりましたか？
                                                        </label>
                                                        <div>
                                                            <div class="form-ctrl non-border">
                                                                <label class="form-title">
                                                                    選択
                                                                    <span class="alert">(必須)</span>
                                                                </label>
                                                                <div class="form-content">
                                                                    <select
                                                                        v-model="values.third_question_select">
                                                                        <option selected disabled>選択してください</option>
                                                                        <option v-for="row of thirdQuestionnaireAnwer" :value="row">
                                                                            {{row}}
                                                                        </option>
                                                                    </select>
                                                                    <ErrorMessage
                                                                        v-if="errors.third_question_select"
                                                                        :message="errors.third_question_select"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="form-ctrl">
                                                                <label class="form-title">
                                                                    自由記述
                                                                </label>
                                                                <div class="form-content">
                                                                    <Textarea
                                                                        v-model="values.third_question_comment"
                                                                        placeholder="何かコメントがありましたらご記載ください"
                                                                        :maxlength="1000"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="survey-footer">
                                                        ご回答ありがとうございました。
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal-body__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            @click="onSubmit"
                                            type="button"
                                            class="btn-blue btn-md shadow"
                                            :disabled="state.isSubmitting"
                                        >
                                            <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                                            保存する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./MeetingEdit.ts" />
<style lang="scss" src="./MeetingEdit.scss" scoped></style>

