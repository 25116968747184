<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="modal-body__content">
                <div class="modal-body__content-title">
                  <span>
                    評価（任意）
                  </span>
                  <i class="fa fa-times" @click="$emit('close')" />
                </div>
                <!-- 説明 -->
                <p class="modal-body__content-sub_title">
                  <span v-if="toOrganization">
                    プロジェクトの内容を評価してください。
                    <br/>
                    <span class="alert">
                      ※評価内容は事務局のみが確認し、企業や受講生は見ることができません。
                    </span>
                  </span>
                  <span v-else>
                    チームを評価してください。
                    <br />
                    <span class="alert">
                      ※評価内容は事務局のみが確認し、企業や受講生は見ることができません。
                    </span>
                  </span>
                </p>
                <form
                  @submit.prevent="toOrganization ? submitOrganize($event) : submitTeam($event)"
                  class="horizontal"
                >
                  <!-- 企業名 -->
                  <div
                    v-if="toOrganization"
                    class="form-ctrl"
                  >
                    <label for class="text">
                        企業名
                    </label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <span class="name">{{ state.organizationInfo.name }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- チーム名 -->
                  <div
                    v-else
                    class="form-ctrl"
                  >
                    <label for class="text">
                        チーム名
                    </label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <span class="name">{{ teamName }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- 評価点数 -->
                  <div class="form-ctrl">
                    <label for class="text">評価点数</label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <select
                          v-model="values.rating"
                          :class="{ invalid: !values.rating }"
                          class="select"
                        >
                          <option value="" selected>評価点数を選択</option>
                          <option
                              v-for="i in 5"
                              :key="i"
                              :value="i"
                              data-icon="<i class='fas fa-star'></i>"
                          >{{i}}</option>
                        </select>
                        <ErrorMessage
                          v-if="errors.rating"
                          :message="errors.rating"
                        ></ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <!-- 評価内容 -->
                  <div class="form-ctrl">
                    <label for class="text">評価内容</label>
                    <div class="form-group-row">
                      <div class="form-group">
                      <Textarea
                        v-model="values.rating_comment"
                        maxlength="1000"
                        :placeholder="toOrganization
                          ? `プロジェクトを進める上で、企業担当者の良かったところや進めやすいと感じたこと、課題等、自由に記載してください。`
                          : `プロジェクトを進める上で、このチームの良かったところや、良い働きをした受講生などを記載してください。`
                        "
                      />
                      <ErrorMessage
                        v-if="errors.rating_comment"
                        :message="errors.rating_comment"
                      ></ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <!-- 下部ボタン -->
                  <div class="modal-body__content-button">
                    <button
                      type="button"
                      class="btn-lg btn-light-gray shadow"
                      @click="$emit('close')"
                    >キャンセル</button>
                    <button
                      type="submit"
                      :disabled="state.isSubmitting"
                      class="btn-lg btn-blue shadow"
                    >
                      <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                      {{ toOrganization ? "評価する" : "チームを評価する" }}
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="./RatingEdit.ts" />
<link type="scss" scoped src="./RatingEdit.scss" />
