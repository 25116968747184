<template>
  <div class="file-uploader">
      <input
          type="file"
          :id="getId()"
          @error="handleUploadError"
          @change="handleUpload"
          ref="fileInputRef"
          accept="application/zip"
      />
      <label
          :class="{
              submitting: isSubmitting
          }"
          :for="getId()"
          class="btn btn-black btn-s sweep-to-top is-upload"
      >
          ファイルアップロード
      </label>
      <span class="uploaded-file" v-if="file">
          {{ file.name }}
          (<span
              class="uploaded-file__delete"
              @click="handleDelete"
          >削除</span>)
      </span>
  </div>
</template>

<script lang="ts" src="./SingleFileUploader.ts" />
<style lang="scss" src="./SingleFileUploader.scss" scoped></style>