import { defineComponent, ref, computed } from "vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
export default defineComponent({
    name: "SingleFileUploader",
    components: {
        ErrorMessage: ErrorMessage,
    },
    props: {
        modelValue: File,
        isSubmitting: Boolean,
        targetId: String,
        accept: String,
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var fileInputRef = ref(null);
        var mimeTypes = {
            'pdf': ['application/pdf'],
            'zip': ['application/zip', 'application/x-zip-compressed'],
            'pptx': ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
            'ppt': ['application/vnd.ms-powerpoint'],
        };
        var defaultMimeTypes = [
            'zip'
        ];
        function checkMimeType(file) {
            var targetMimeTypes = props.accept ? props.accept.split(',') : defaultMimeTypes;
            var result = false;
            targetMimeTypes.forEach(function (mimeType) {
                if (!mimeTypes[mimeType]) {
                    return false;
                }
                if (mimeTypes[mimeType].includes(file.type)) {
                    result = true;
                }
            });
            if (!result) {
                alert(targetMimeTypes.join('または') + 'ファイルをご指定ください。');
            }
            return result;
        }
        function getId() {
            return props.targetId ? props.targetId : 'upload-file';
        }
        var handleUpload = function (e) {
            var _a, _b, _c, _d;
            var file = ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0]) || ((_d = (_c = e.dataTransfer) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d[0]);
            if (!file) {
                return;
            }
            if (!checkMimeType(file)) {
                return;
            }
            if (file.size && (file.size / 1024 / 1024) > 30) {
                alert('30MB以内のファイルをご指定ください。');
                return;
            }
            emit('update:modelValue', file);
            ;
            fileInputRef.value.value = "";
        };
        var handleUploadError = function () {
            alert('送信エラーが起きました。お手数ですが、再度やり直してください。');
        };
        var handleDelete = function () {
            emit('update:modelValue', null);
        };
        return {
            handleUpload: handleUpload,
            handleUploadError: handleUploadError,
            handleDelete: handleDelete,
            fileInputRef: fileInputRef,
            file: computed(function () { return props.modelValue; }),
            getId: getId,
        };
    }
});
