<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="modal-body">
                                <div class="modal-body__content">
                                    <div class="modal-body__content-title">
                                        <span>
                                            成果提出
                                        </span>
                                        <i class="fa fa-times" @click="$emit('close')" />
                                    </div>
                                    <div v-if="state.preload" class="modal-body__content-body meeting-edit">
                                        <Preloaders :count="10" />
                                    </div>
                                    <div v-else class="modal-body__content-body meeting-edit">
                                        <p>
                                            クライアント企業に打ち合わせ等での成果レビュー後に、プロジェクトの最終成果報告を行ってください。
                                            <br />
                                            成果物がある場合は成果物も添付してください。
                                        </p>
                                        <div class="meeting-edit__form">
                                            <!-- 提出日 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    提出日
                                                </label>
                                                <div class="form-content">
                                                    {{ submitDate }}
                                                </div>
                                            </div>
                                            <!-- 成果報告書(zip) -->
                                            <div class="form-ctrl">
                                            <label class="form-title">
                                              成果報告書(zip)<br />
                                              <span class="alert">
                                                  ※30MBまで　(必須)
                                              </span>
                                            </label>
                                            <div class="form-content">
                                              <SingleFileUploader
                                                  v-model="state.uploadReportFile"
                                                  :accept="'zip'"
                                                  :targetId="'upload-file-report'"
                                              />
                                              <span class="uploaded-files" v-if="achievement && achievement.report_files && achievement.report_files.length > 0">
                                                        <span v-if="state.deleteReportFileId !== achievement.report_files[achievement.report_files.length - 1].id">
                                                            <a
                                                                class="uploaded-files__interactive"
                                                                :href="achievement.report_files[achievement.report_files.length - 1].url"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {{ achievement.report_files[achievement.report_files.length - 1].name }}
                                                            </a>
                                                                (<span
                                                            class="uploaded-files__interactive"
                                                            @click="state.deleteReportFileId = achievement.report_files[achievement.report_files.length - 1].id">
                                                                    削除
                                                                </span>)
                                                        </span>
                                                    </span>
                                              <p class="alert">
                                                ※ファイル名には半角英数字,ハイフン,アンダースコアをご利用ください。<br>
                                                ※1ファイルのみアップロード可能です。更新すると前のファイルは上書きされます。
                                              </p>
                                            </div>
                                          </div>
                                            <!-- 成果物(zip) -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    成果物(zip)<br />
                                                    <span class="alert">
                                                        ※30MBまで　(任意)
                                                    </span>
                                                </label>
                                                <div class="form-content">
                                                    <SingleFileUploader
                                                        v-model="state.uploadFile"
                                                        :targetId="'upload-file'"
                                                    />
                                                    <span class="uploaded-files" v-if="achievement && achievement.files && achievement.files.length > 0">
                                                        <span v-if="state.deleteFileId !== achievement.files[achievement.files.length - 1].id">
                                                            <a
                                                                class="uploaded-files__interactive"
                                                                :href="achievement.files[achievement.files.length - 1].url"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {{ achievement.files[achievement.files.length - 1].name }}
                                                            </a>
                                                                (<span
                                                                    class="uploaded-files__interactive"
                                                                    @click="state.deleteFileId = achievement.files[achievement.files.length - 1].id">
                                                                    削除
                                                                </span>)
                                                        </span>
                                                    </span>
                                                    <p class="alert">
                                                        ※ファイル名には半角英数字,ハイフン,アンダースコアをご利用ください。<br>
                                                        ※1ファイルのみアップロード可能です。更新すると前のファイルは上書きされます。
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- 成果内容 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    成果内容
                                                </label>
                                                <div class="form-content">
                                                    <Textarea
                                                        placeholder="2000文字以内で入力してください"
                                                        :maxlength="2000"
                                                        v-model="values.description"
                                                    />
                                                    <ErrorMessage
                                                        v-if="errors.description"
                                                        :message="errors.description"
                                                    />
                                                </div>
                                            </div>
                                            <!-- 備考 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    備考
                                                </label>
                                                <div class="form-content">
                                                    <Textarea
                                                        placeholder="500文字以内で入力してください"
                                                        :maxlength="500"
                                                        v-model="values.note"
                                                    />
                                                    <ErrorMessage
                                                        v-if="errors.note"
                                                        :message="errors.note"
                                                    />
                                                </div>
                                            </div>

                                          <div class="form-ctrl">
                                              皆様にご作成いただいた成果報告書は、企業による確認の上、公開への同意をいただいたのちに、経済産業省HPへ公開させていただきます。<br>
                                              その際、以下2点ご了承ください。提出をもってご同意いただいたものとみなします。<br>
                                              ・受講生チームの皆様間で、公開を許諾する内容のみ、成果報告書にはご記載ください。<br>
                                              ・一部、企業側からの情報の修正や削除、非公開への切り替え等が行われる可能性がございます。<br>
                                          </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal-body__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            @click="onSubmit"
                                            type="button"
                                            class="btn-blue btn-md shadow"
                                            :disabled="state.isSubmitting"
                                        >
                                            <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                                            保存する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./AchievementEdit.ts" />
<style lang="scss" src="./AchievementEdit.scss" scoped></style>

