import { __assign, __awaiter, __generator } from "tslib";
import LanguageUtil from "@/commons/LanguageUtil";
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { ModalType } from "@/presentation/views/organization/project-detail/management/ProjectDetailManagement";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import Input from "@/presentation/components/input/Input.vue";
import TagsInput from '@/presentation/components/tags-input/TagsInput.vue';
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import FileUploader from "@/presentation/components/file-uploader/FileUploader.vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import { useStore } from "vuex";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import MilestoneInteractor from "@/domain/usecases/MilestoneInteractor";
import QuestionnaireInteractor from "@/domain/usecases/QuestionnaireInteractor";
import CommonService from "@/services/CommonService";
export default defineComponent({
    name: "MeetingEditDifferenceDisplayPopup",
    components: {
        Input: Input,
        TagsInput: TagsInput,
        Textarea: Textarea,
        DatePicker: DatePicker,
        FileUploader: FileUploader,
        ErrorMessage: ErrorMessage,
        Preloaders: Preloaders,
    },
    props: {
        type: String,
        milestone: {
            type: Object,
            required: false,
            default: null
        },
        latestMilestone: {
            type: Object,
            required: false,
            default: null
        },
        questionnaire: {
            type: Object,
            required: false,
            default: null
        },
        latestQuestionnaire: {
            type: Object,
            required: false,
            default: null
        },
        disableSelectReview: Boolean,
    },
    setup: function (_a, _b) {
        var _this = this;
        var type = _a.type, milestone = _a.milestone, latestMilestone = _a.latestMilestone, latestQuestionnaire = _a.latestQuestionnaire, disableSelectReview = _a.disableSelectReview;
        var emit = _b.emit;
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            collapsed: true,
            meetingDate: {
                date: null,
                startHour: null,
                startMinute: '00',
                endHour: null,
                endMinute: '00',
            },
            latestMilestone: latestMilestone,
            latestQuestionnaire: latestQuestionnaire,
            milestone: milestone,
            uploadFiles: [],
            deleteFileIds: [],
            preload: true,
            isSubmitting: false,
            milestoneSource: [],
        });
        var startDate = state.latestMilestone.meeting_start_at
            ? state.latestMilestone.meeting_start_at.replace(/-/g, '/').slice(0, 16)
            : "";
        var endDate = state.latestMilestone.meeting_end_at
            ? state.latestMilestone.meeting_end_at.replace(/-/g, '/').slice(0, 16)
            : "";
        var displayDate = startDate + " \u301C " + endDate;
        var isKickoff = type === ModalType.KickoffEdit;
        var isLeader = computed(function () { return store.state.projectContractorStatus === 'LEADER'; });
        var isUser = computed(function () { return CommonService.isUser(); });
        // 会議実施日時以降は、「MTG終了後」を固定で開いておく
        var staticExpanded = computed(function () {
            if (!(values === null || values === void 0 ? void 0 : values.meeting_start_at))
                return false;
            var dt = new Date();
            var numberFormat = function (v) { return v < 10 ? "0" + v : v; };
            var currentDate = dt.getFullYear() + "-" + numberFormat(dt.getMonth() + 1) + "-" + numberFormat(dt.getDate()) + " " + numberFormat(dt.getHours()) + ":" + numberFormat(dt.getMinutes());
            return currentDate >= values.meeting_start_at ? true : false;
        });
        // - - -
        // for date input
        var hourSource = computed(function () {
            var arr = [];
            for (var n = 1; n <= 24; n++) {
                arr.push(n < 10 ? "0" + n : String(n));
            }
            return arr;
        });
        var minuteSource = computed(function () { return ['00', '15', '30', '45']; });
        // - - -
        // form controls
        watch(function () {
            var data = state.meetingDate;
            return [data.date, data.startHour, data.startMinute, data.endHour, data.endMinute].join('');
        }, function () {
            var data = state.meetingDate;
            data.date && data.startHour && data.startMinute
                ? setValues({ meeting_start_at: data.date + " " + data.startHour + ":" + data.startMinute })
                : setValues({ meeting_start_at: null });
            data.date && data.endHour && data.endMinute
                ? setValues({ meeting_end_at: data.date + " " + data.endHour + ":" + data.endMinute })
                : setValues({ meeting_end_at: null });
        });
        // - - -
        // validations
        var _c = useForm({
            initialValues: {
                meeting_type: isKickoff ? 'KICKOFF' : null,
                meeting_start_at: null,
                meeting_end_at: null,
                agenda: null,
                team_members: [],
                organization_members: [],
                note: null,
                promises: null,
                issues: null,
                expected_completion_at: null,
                need_help: false,
                help_note: null
            }
        }), isSubmitting = _c.isSubmitting, values = _c.values, errors = _c.errors, setValues = _c.setValues, handleSubmit = _c.handleSubmit;
        var requiredIfExpanded = function (value) {
            if (!state.collapsed || staticExpanded.value) {
                if (!value || (Array.isArray(value) && value.length <= 0)) {
                    return state.aLang.validation_required;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        };
        useField("id", yup.number());
        useField("meeting_type", yup.string().required(state.aLang.validation_required));
        useField("meeting_start_at", yup.string().nullable().required(state.aLang.validation_required), { validateOnValueUpdate: false });
        useField("meeting_end_at", function (value) {
            if (!value)
                return state.aLang.validation_required;
            if (values.meeting_start_at && values.meeting_start_at >= value) {
                return '終了時間は開始時間より後を選択してください';
            }
            return true;
        }, { validateOnValueUpdate: false });
        useField("agenda", yup.string().nullable());
        useField("organization_members", requiredIfExpanded);
        useField("team_members", requiredIfExpanded);
        useField("note", requiredIfExpanded);
        useField("promises", yup.string().nullable());
        useField("issues", yup.string().nullable());
        useField("expected_completion_at", function (value) {
            if (isKickoff && !state.collapsed && !value)
                return state.aLang.validation_required;
            return true;
        });
        useField("expected_completion_at", yup.string().nullable());
        useField("need_help", yup.boolean().nullable());
        useField("help_note", yup.string().nullable());
        // - - -
        // actions
        if (isKickoff && latestQuestionnaire) {
            useField("first_question_select", requiredIfExpanded);
            useField("first_question_comment", yup.string().nullable());
            useField("second_question_select", requiredIfExpanded);
            useField("second_question_comment", yup.string().nullable());
            useField("third_question_select", requiredIfExpanded);
            useField("third_question_comment", yup.string().nullable());
        }
        var firstQuestionnaireAnwer = [
            "楽しみだなと感じた/ワクワクする様な内容だった",
            "大変そうだな/不安だなと感じた",
            "まだわからない"
        ];
        var secondQuestionnaireAnwer = [
            "明確に提示され、今後の取り組みの方向性もイメージできた",
            "明確に提示されたが、今後の取り組みの方向性は要相談だと感じた",
            "課題があまり明確に示されなかったと感じた"
        ];
        var thirdQuestionnaireAnwer = [
            "明確になった",
            "明確にならなかった"
        ];
        var defaultMiletonneNote = "【決定事項】\n\n\n【宿題事項】\n\n\n【次回の議題】\n\n\n【詳細】";
        onMounted(function () {
            var _a, _b, _c, _d;
            if (state.milestone) {
                // 実施日時を入力フォーム用にコンバート
                var _e = state.milestone, meeting_start_at = _e.meeting_start_at, meeting_end_at = _e.meeting_end_at;
                if (meeting_start_at && meeting_end_at) {
                    var numberFormat = function (v) { return v < 10 ? "0" + v : v; };
                    var startDt = new Date(meeting_start_at.replaceAll("-", "/"));
                    var endDt = new Date(meeting_end_at.replaceAll("-", "/"));
                    state.meetingDate = {
                        date: meeting_start_at.split(' ')[0],
                        startHour: numberFormat(startDt.getHours()),
                        startMinute: numberFormat(startDt.getMinutes()),
                        endHour: numberFormat(endDt.getHours()),
                        endMinute: numberFormat(endDt.getMinutes()),
                    };
                }
                setValues(__assign(__assign(__assign({}, values), state.milestone), { expected_completion_at: ((_a = state.milestone.expected_completion_at) === null || _a === void 0 ? void 0 : _a.includes(" ")) ? state.milestone.expected_completion_at.split(" ")[0]
                        : (_b = state.milestone.expected_completion_at) === null || _b === void 0 ? void 0 : _b.replaceAll("-", "/"), organization_members: ((_c = state.milestone.organization_members) === null || _c === void 0 ? void 0 : _c.concat()) || [], team_members: ((_d = state.milestone.team_members) === null || _d === void 0 ? void 0 : _d.concat()) || [] }));
            }
            else {
                setValues({
                    note: defaultMiletonneNote
                });
            }
            state.preload = false;
        });
        var MEETING_TYPES = {
            KICKOFF: 'キックオフ',
            REGULAR: '会議',
            REVIEW: '最終報告',
        };
        var expectedCompletionAt = (function (uploadMilestone) {
            var _a;
            if (!uploadMilestone.expected_completion_at) {
                return new Date().toString();
            }
            return (_a = uploadMilestone.expected_completion_at) === null || _a === void 0 ? void 0 : _a.replaceAll('/', '-');
        });
        var conflictEditting = (function (initialUpdatedAt, currentUpdatedAt) {
            if (initialUpdatedAt !== currentUpdatedAt) {
                return true;
            }
            return false;
        });
        var onSubmit = handleSubmit(function () { return __awaiter(_this, void 0, void 0, function () {
            var uploaded_files, teamInteractor, _i, _a, file, extension, preSignedUrlResponse, e_1, milestoneInteractor, kickoffQuestionnaireInteractor, tmpMilestone, uploadMilestone, newMilestone, newQuestionnaire, upsertResponse, milestoneSource, questionnaireSource, currentMilestone, currentMilestoneUpdatedAt, currentQuestionnaireUpdatedAt, milestoneConflict, questionnaireConflict, modalElement, userChoice, milestoneId, kickoffQuestionnaireInteractor_1, e_2, _loop_1, _b, _c, fileId, e_3;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        state.isSubmitting = true;
                        uploaded_files = [];
                        if (!(state.uploadFiles.length > 0)) return [3 /*break*/, 7];
                        teamInteractor = DIContainer.instance.get(TeamInteractor);
                        _i = 0, _a = state.uploadFiles;
                        _e.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 7];
                        file = _a[_i];
                        extension = void 0;
                        if (file.type === 'application/pdf') {
                            extension = "pdf";
                        }
                        else if (['application/zip', 'application/x-zip-compressed'].includes(file.type)) {
                            extension = "zip";
                        }
                        else {
                            alert('PDFまたはZIPファイルをご指定ください。');
                            return [3 /*break*/, 7];
                        }
                        return [4 /*yield*/, teamInteractor.createPreSignedUrl(extension)];
                    case 2:
                        preSignedUrlResponse = _e.sent();
                        _e.label = 3;
                    case 3:
                        _e.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, teamInteractor.uploadToPreSignedUrl(preSignedUrlResponse.data, file)];
                    case 4:
                        _e.sent();
                        uploaded_files.push({
                            name: file.name,
                            url: preSignedUrlResponse.data.url
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _e.sent();
                        console.error(e_1);
                        alert("ファイルのアップロードに失敗しました。");
                        return [3 /*break*/, 6];
                    case 6:
                        _i++;
                        return [3 /*break*/, 1];
                    case 7:
                        milestoneInteractor = DIContainer.instance.get(MilestoneInteractor);
                        kickoffQuestionnaireInteractor = DIContainer.instance.get(QuestionnaireInteractor);
                        tmpMilestone = __assign(__assign({}, values), { meeting_start_at: values.meeting_start_at + ":00", meeting_end_at: values.meeting_end_at + ":00", project_id: store.state.projectInfo.project_id, team_id: store.state.teamInfo.team_id, uploaded_files: uploaded_files });
                        uploadMilestone = Object.fromEntries(Object.entries(tmpMilestone).filter(function (_a) {
                            var _ = _a[0], value = _a[1];
                            return value != null;
                        }));
                        _e.label = 8;
                    case 8:
                        _e.trys.push([8, 23, , 24]);
                        upsertResponse = void 0;
                        return [4 /*yield*/, milestoneInteractor.getMilestones(store.state.teamInfo.team_id)];
                    case 9:
                        milestoneSource = _e.sent();
                        return [4 /*yield*/, kickoffQuestionnaireInteractor.isQuestionnaireAnswered(store.state.teamInfo.team_id)];
                    case 10:
                        questionnaireSource = _e.sent();
                        currentMilestone = isKickoff ? milestoneSource.data.find(function (milestone) { return milestone.meeting_type === "KICKOFF"; }) : milestoneSource.data.find(function (milestone) { return milestone.id === uploadMilestone.id; });
                        currentMilestoneUpdatedAt = currentMilestone === null || currentMilestone === void 0 ? void 0 : currentMilestone.updated_at;
                        currentQuestionnaireUpdatedAt = questionnaireSource.data.updated_at;
                        milestoneConflict = conflictEditting(state.latestMilestone.updated_at, currentMilestoneUpdatedAt);
                        questionnaireConflict = conflictEditting(state.latestQuestionnaire.updated_at, currentQuestionnaireUpdatedAt);
                        if (milestoneConflict || (questionnaireConflict && isKickoff)) {
                            state.isSubmitting = false;
                            state.milestone = uploadMilestone;
                            if (currentMilestone !== undefined) {
                                state.latestMilestone = currentMilestone;
                                state.latestQuestionnaire = questionnaireSource.data;
                            }
                            alert("新たに更新されたデータがあります。確認してください");
                            modalElement = document.getElementById('my-modal');
                            if (modalElement) {
                                modalElement.scrollIntoView({ behavior: 'smooth' });
                            }
                            return [2 /*return*/];
                        }
                        userChoice = window.confirm("編集中の内容で更新しますか？");
                        if (!userChoice) {
                            alert("更新をキャンセルしました。");
                            state.isSubmitting = false;
                            emit("close");
                            return [2 /*return*/];
                        }
                        milestoneId = state.milestone.id;
                        if (currentMilestone === null || currentMilestone === void 0 ? void 0 : currentMilestone.id) {
                            milestoneId = milestoneId || currentMilestone.id;
                        }
                        if (!(uploadMilestone.meeting_type === "KICKOFF" && latestQuestionnaire && staticExpanded.value)) return [3 /*break*/, 18];
                        kickoffQuestionnaireInteractor_1 = DIContainer.instance.get(QuestionnaireInteractor);
                        uploadMilestone.expected_completion_at = expectedCompletionAt(uploadMilestone);
                        if (!state.milestone) return [3 /*break*/, 15];
                        if (!currentQuestionnaireUpdatedAt) return [3 /*break*/, 12];
                        return [4 /*yield*/, milestoneInteractor.updateMilestoneAndUpdateQuestionnaire(milestoneId, uploadMilestone)];
                    case 11:
                        upsertResponse = _e.sent();
                        return [3 /*break*/, 14];
                    case 12: return [4 /*yield*/, milestoneInteractor.updateMilestoneAndCreateQuestionnaire(milestoneId, uploadMilestone)];
                    case 13:
                        upsertResponse = _e.sent();
                        _e.label = 14;
                    case 14:
                        kickoffQuestionnaireInteractor_1.isQuestionnaireAnswered(store.state.teamInfo.team_id).then(function (result) {
                            newQuestionnaire = result.data;
                        }).catch(function (error) {
                            newQuestionnaire = [];
                        });
                        return [3 /*break*/, 17];
                    case 15: return [4 /*yield*/, milestoneInteractor.createMilestoneAndCreateQuestionnaire(uploadMilestone)];
                    case 16:
                        upsertResponse = _e.sent();
                        kickoffQuestionnaireInteractor_1.isQuestionnaireAnswered(store.state.teamInfo.team_id).then(function (result) {
                            newQuestionnaire = result.data;
                        }).catch(function (error) {
                            newQuestionnaire = [];
                        });
                        _e.label = 17;
                    case 17: return [3 /*break*/, 22];
                    case 18:
                        if (!!state.milestone) return [3 /*break*/, 20];
                        return [4 /*yield*/, milestoneInteractor.createMilestone(uploadMilestone)];
                    case 19:
                        upsertResponse = _e.sent();
                        return [3 /*break*/, 22];
                    case 20: return [4 /*yield*/, milestoneInteractor.updateMilestone(milestoneId, uploadMilestone)];
                    case 21:
                        upsertResponse = _e.sent();
                        _e.label = 22;
                    case 22:
                        newMilestone = upsertResponse.data;
                        return [3 /*break*/, 24];
                    case 23:
                        e_2 = _e.sent();
                        state.isSubmitting = false;
                        console.error(e_2);
                        alert("登録に失敗しました");
                        return [2 /*return*/];
                    case 24:
                        if (!(state.deleteFileIds.length > 0)) return [3 /*break*/, 31];
                        _e.label = 25;
                    case 25:
                        _e.trys.push([25, 30, , 31]);
                        _loop_1 = function (fileId) {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, milestoneInteractor.deleteMilestoneFile(newMilestone.id, fileId)];
                                    case 1:
                                        _a.sent();
                                        newMilestone.files = (_d = newMilestone.files) === null || _d === void 0 ? void 0 : _d.filter(function (file) { return file.id !== fileId; });
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _b = 0, _c = state.deleteFileIds;
                        _e.label = 26;
                    case 26:
                        if (!(_b < _c.length)) return [3 /*break*/, 29];
                        fileId = _c[_b];
                        return [5 /*yield**/, _loop_1(fileId)];
                    case 27:
                        _e.sent();
                        _e.label = 28;
                    case 28:
                        _b++;
                        return [3 /*break*/, 26];
                    case 29: return [3 /*break*/, 31];
                    case 30:
                        e_3 = _e.sent();
                        console.error(e_3);
                        state.isSubmitting = false;
                        alert("ファイルの削除に失敗しました");
                        return [2 /*return*/];
                    case 31:
                        emit('complete', newMilestone);
                        emit('complet_questionnaire', newQuestionnaire);
                        emit('close');
                        state.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        }); });
        return {
            isKickoff: isKickoff,
            isLeader: isLeader,
            values: values,
            errors: errors,
            hourSource: hourSource,
            minuteSource: minuteSource,
            state: state,
            firstQuestionnaireAnwer: firstQuestionnaireAnwer,
            secondQuestionnaireAnwer: secondQuestionnaireAnwer,
            thirdQuestionnaireAnwer: thirdQuestionnaireAnwer,
            onSubmit: onSubmit,
            displayDate: displayDate,
            staticExpanded: staticExpanded,
            isUser: isUser,
            MEETING_TYPES: MEETING_TYPES,
        };
    }
});
